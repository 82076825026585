<template>
  <div class="page-container">
    <section id="asr">
      <!-- 背景板 -->
      <p class="bg">
        <img src="../assets/images/asr_bg.png" />
      </p>
      <!-- 背景板 -->
      <!-- 内容显示区 -->
      <div class="result-area" ref="resultContainer">
        <div class="result-container">
          <span style="color: #fff" ref="endText">{{ endText }}</span>
          <span style="color: #bababa">{{ changedText }}</span>
        </div>
      </div>
      <!-- 内容显示区 -->
      <!-- 底部操作栏 -->
      <div class="operation">
        <el-button
          @click="
            changedText = '';
            endText = '';
          "
        >
          <i class="el-icon-delete"></i>
          <span>清屏</span>
        </el-button>
        <div class="recording">
          <!-- 开始按钮 -->
          <p id="start-btn" v-if="!isStart" @click="startRec">
            <img src="~@/assets/images/start.png" />
          </p>
          <!-- 动态效果 -->
          <p id="finish-btn" v-else @click="finishRec">
            <img :src="recImg" />
          </p>
          <p class="asr-tips">{{ isStart ? "正在听写..." : "点击按钮开始" }}</p>
        </div>
      </div>
      <!-- 底部操作栏 -->
    </section>
  </div>
</template>

<script>
import play1 from "../assets/images/play1.png";
import play2 from "../assets/images/play2.png";
import play3 from "../assets/images/play3.png";
import play4 from "../assets/images/play4.png";
import WaveSurfer from "wavesurfer.js";
export default {
  name: "asr",
  data() {
    return {
      isStart: false,
      editDrawer: false,
      recordImgs: [play1, play2, play3, play4],
      recImg: play1,
      canSet: false,
      canEdit: true,
      langType: "zh-cmn-Hans-CN",
      duration: "00:00:00", // 时长
      showList: ["middle", "ITN"],
      wavesurfer: null,
      isPlay: false,
      isClick: false,
      recorders: null,
      ws: null,
      changedText: "", // 识别中
      endText: "", // 识别结束,
      timer: null,
      imgIndex: 0,
      asrResult: [],
      blob: null, // 音频文件
      isClicked: false,
      bol: false,
      sensitive: "",
    };
  },
  mounted() {
    this.recOpen();
  },
  beforeDestroy() {
    if (this.ws) {
      this.finishRec();
    }
  },
  methods: {
    // 清空文本
    clearText() {
      this.editDrawer = false;
      this.asrResult = [];
      this.duration = "";
    },
    // 获取音频文件结束
    // 初始化麦克风
    recOpen() {
      const _this = this;
      // 录音对象
      var Recorder = function (stream) {
        var sampleBits = 16; // 输出采样数位 8, 16
        var sampleRate = 16000; // 输出采样率
        var context = new AudioContext();
        var audioInput = context.createMediaStreamSource(stream);
        var recorder = context.createScriptProcessor(4096, 1, 1);
        var audioData = {
          size: 0, // 录音文件长度
          buffer: [], // 录音缓存
          inputSampleRate: 48000, // 输入采样率
          inputSampleBits: 16, // 输入采样数位 8, 16
          outputSampleRate: sampleRate, // 输出采样数位
          oututSampleBits: sampleBits, // 输出采样率
          clear: function () {
            this.buffer = [];
            this.size = 0;
          },
          input: function (data) {
            this.buffer.push(new Float32Array(data));
            this.size += data.length;
          },
          compress: function () {
            // 合并压缩
            var data = new Float32Array(this.size);
            var offset = 0;
            for (var i = 0; i < this.buffer.length; i++) {
              data.set(this.buffer[i], offset);
              offset += this.buffer[i].length;
            }
            // 压缩
            var compression = parseInt(
              this.inputSampleRate / this.outputSampleRate
            );
            var length = data.length / compression;
            var result = new Float32Array(length);
            let index = 0;
            let j = 0;
            while (index < length) {
              result[index] = data[j];
              j += compression;
              index++;
            }
            return result;
          },
          encodePCM: function () {
            var sampleBits = Math.min(
              this.inputSampleBits,
              this.oututSampleBits
            );
            var bytes = this.compress();
            var dataLength = bytes.length * (sampleBits / 8);
            var buffer = new ArrayBuffer(dataLength);
            var data = new DataView(buffer);
            var offset = 0;
            for (var i = 0; i < bytes.length; i++, offset += 2) {
              var s = Math.max(-1, Math.min(1, bytes[i]));
              data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
            }
            return new Blob([data]);
          },
        };
        var sendData = function () {
          // 对以获取的数据进行处理(分包)
          var reader = new FileReader();
          reader.readAsArrayBuffer(audioData.encodePCM());
          reader.onloadend = function () {
            var binary = reader.result;
            var array = new Uint8Array(binary);
            _this.bol = array.every((item) => {
              return item === 0;
            });
            if (_this.ws === null) {
              recorder.disconnect();
            } else {
              if (_this.bol) {
                _this.finishConfirm();
              }
              _this.ws.send(binary);
            }
          };
          audioData.clear(); // 每次发送完成则清理掉旧数据
        };
        this.start = function () {
          audioInput.connect(recorder);
          console.log(audioInput, "audioInput");
          console.log(context,'context');
          recorder.connect(context.destination);
          console.log(recorder, "recorder");
        };
        this.stop = function () {
          recorder.disconnect();
        };
        this.getBlob = function () {
          return audioData.encodePCM();
        };
        this.clear = function () {
          audioData.clear();
        };
        this.getaudio = function () {
          audioData.getFullWavData();
        };
        recorder.onaudioprocess = function (e) {
          var inputBuffer = e.inputBuffer.getChannelData(0);
          audioData.input(inputBuffer);
          sendData();
        };
      };
      navigator.getUserMedia(
        {
          audio: true,
        },
        (mediaStream) => {
          this.initRecorder(new Recorder(mediaStream));
        },
        (error) => {
          switch (error.message || error.name) {
            case "PERMISSION_DENIED":
            case "PermissionDeniedError":
              this.$message.error("用户拒绝提供信息");
              break;
            case "NOT_SUPPORTED_ERROR":
            case "NotSupportedError":
              this.$message.error("浏览器不支持硬件设备");
              break;
            case "MANDATORY_UNSATISFIED_ERROR":
            case "MandatoryUnsatisfiedError":
              this.$message.error("无法发现指定的硬件设备");
              break;
            default:
              this.$message.error("无法打开麦克风");
              break;
          }
        }
      );
    },
    // 音频获取出错
    finishConfirm() {
      if (!this.isClicked) {
        this.$confirm("获取麦克风音频失败，请检查设置。", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        })
          .then(() => {
            this.finishRec();
          })
          .catch(() => {
            this.isClicked = false;
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
      this.isClicked = true;
    },
    // 初始化recorders
    initRecorder(rec) {
      this.recorders = rec;
    },
    // 开始录音
    startRec() {
      this.isClick = false;
      this.clearText();
      this.endText = "";
      this.changedText = "";
      let _this = this;
      this.canEdit = true;
      this.canSet = true;
      this.asrResult = [];
      this.ws = new WebSocket(`ws://gxq.server.huiyan-tech.com:7100/ws/v1`);
      this.ws.binaryType = "arraybuffer";
      this.ws.onopen = () => {
        let sendData = {
          header: {
            namespace: "SpeechTranscriber",
            name: "StartTranscription",
          },
          payload: {
            lang_type: this.langType,
            format: "pcm",
            sample_rate: 16000,
            enable_intermediate_result: this.showList.includes("middle"),
            enable_punctuation_prediction: true,
            enable_inverse_text_normalization: this.showList.includes("ITN"),
            hotwords_id: "",
            forbidden_words_id: "",
            hotwords_weight: 0.8,
            enable_modal_particle_filter: this.showList.includes("modal"),
          },
        };
        if (this.ws.readyState == 1) {
          this.ws.send(JSON.stringify(sendData));
        }
      };
      this.ws.onmessage = function (msg) {
        let res = JSON.parse(msg.data);
        if (res.header.name === "TaskFailed") {
          _this.$message.error("服务器断开连接，请检查网络后重试。");
          _this.isStart = false;
        } else {
          _this.recorders.start();
        }
        // 动态切换麦克风
        if (res.header.name === "TranscriptionStarted") {
          _this.timer = setInterval(_this.imgF, 200);
          _this.isStart = true;
          _this.canSet = true;
        }
        if (res.header.name === "TranscriptionResultChanged") {
          _this.changedText = res.payload.result;
          _this.$nextTick(() => {
            if (_this.$refs.resultContainer) {
              _this.$refs.resultContainer.scrollTo({
                top: _this.$refs.endText.offsetHeight + 80,
                behavior: "smooth",
              });
            }
          });
        }
        if (res.header.name === "SentenceEnd") {
          _this.changedText = "";
          _this.endText += res.payload.result;
          _this.asrResult.push(res.payload);
          _this.$nextTick(() => {
            if (_this.$refs.resultContainer) {
              _this.$refs.resultContainer.scrollTo({
                top: _this.$refs.endText.offsetHeight + 80,
                behavior: "smooth",
              });
            }
          });
        }
        if (res.header.name === "TranscriptionCompleted") {
          _this.blob = res.header.task_id;
          _this.canSet = false;
          _this.canEdit = false;
          _this.isStart = false;
          _this.isClicked = false;
          if (_this.ws) {
            _this.ws.close();
            _this.ws = null;
          }
        }
      };
      this.ws.onerror = () => {
        this.$message.error("服务器断开连接，请稍后重试。");
        this.ws = null;
        clearInterval(this.timer);
        this.canSet = false;
        this.canEdit = false;
        this.isStart = false;
        this.isClicked = false;
      };
      this.ws.onclose = () => {
        if (!this.isClick) {
          this.$message.error("服务器断开连接，请稍后重试。");
        }
        this.ws = null;
        clearInterval(this.timer);
        this.canSet = false;
        this.canEdit = false;
        this.isStart = false;
        this.isClicked = false;
      };
    },
    // 结束录音
    finishRec() {
      this.isClick = true;
      this.recorders.stop();
      let sendData = {
        header: {
          namespace: "SpeechTranscriber",
          name: "StopTranscription",
        },
      };
      this.ws.send(JSON.stringify(sendData));
      this.ws = null;
      clearInterval(this.timer);
    },
    // 切换图片
    imgF() {
      this.imgIndex++;
      if (this.imgIndex >= this.recordImgs.length) {
        this.imgIndex = 0;
      }
      this.recImg = this.recordImgs[this.imgIndex];
    },
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}
.page-container {
  width: 100%;
  height: 100%;
}
#no-set {
  p:nth-of-type(2),
  p:nth-of-type(3) {
    font-size: 16px;
    padding-bottom: 20px;
  }
  p:nth-of-type(2) {
    font-weight: 900;
  }
}
.set-logo {
  height: 88px;
  margin-bottom: 30px;
  i {
    font-size: 88px;
    color: #409eff;
  }
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.result-area {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 248px;
  padding: 30px;
  overflow-y: auto;
  span {
    line-height: 30px;
    font-size: 20px;
  }
}
.operation {
  height: 120px;
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 20px;
  padding-left: 8%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  .el-button {
    font-size: 16px;
    color: #fff;
    margin-right: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
    i {
      font-size: 18px;
    }
  }
  .recording {
    width: 173px;
    height: 177px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    .asr-tips {
      cursor: auto;
    }
  }
  .asr-tips {
    color: #fff;
    position: absolute;
    right: -140px;
    top: 140px;
  }
  .edit-btn {
    position: absolute;
    right: 60px;
    /deep/ span {
      display: flex;
      align-items: center;
      span {
        line-height: 25px;
        padding-left: 8px;
      }
    }
  }
}
.setting-content {
  padding-left: 40px;
  .langs {
    height: 60px;
    span {
      padding-right: 10px;
    }
  }
  .show-text {
    height: 120px;
    .el-checkbox-group {
      width: 160px;
      display: flex;
      flex-direction: column;
      /deep/ .el-checkbox {
        margin-bottom: 20px;
      }
    }
  }
  .hotword {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
    }
    .el-select {
      margin-right: 10px;
    }
    span:nth-of-type(2) {
      color: #409eff;
      font-weight: 900;
      cursor: pointer;
    }
  }
}
.el-drawer__wrapper {
  left: 0;
  /deep/ .el-drawer {
    width: 100% !important;
  }
}
.asr-result {
  position: absolute;
  top: 80px;
  left: 30px;
  bottom: 0;
  right: 120px;
  .file-infos {
    display: flex;
    justify-content: space-between;
    .file-name {
      color: #409eff;
      font-size: 22px;
      padding-bottom: 5px;
    }
    .file-duration {
      color: rgb(85, 84, 84);
    }
  }
  .info-right {
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 20px;
      margin-top: 0;
      height: 36px;
      line-height: 10px;
    }
  }
  #spanBox {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 110px;
    background: #eee;
    padding: 30px;
    line-height: 28px;
    overflow-y: auto;
    span {
      outline: none;
    }
  }
  .btns {
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .pause,
    .play {
      width: 46px;
      height: 46px;
    }
    .back img,
    .forward img {
      width: 36px;
      height: 36px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .subtitle-model {
    margin-bottom: 20px;
  }
}
.wavesurfer {
  height: 46px;
  margin-top: 10px;
}
.el-icon-question {
  color: #76b7f8;
  padding-left: 10px;
}
</style>
